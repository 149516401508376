var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'maincon':true
}},[_c('div',{staticClass:"bmap-page-container"},[_c('div',{staticClass:"plugin"},[_c('div',{class:{'pbtn':true, 'activebtn':'vector'=== _vm.currentMapLayer.type},on:{"click":function($event){return _vm.toggleMapType('vector')}}},[_vm._v("矢量地图")]),_c('div',{class:{'pbtn':true, 'activebtn':'satellite'=== _vm.currentMapLayer.type},on:{"click":function($event){return _vm.toggleMapType('satellite')}}},[_vm._v("影像地图")])]),_c('l-map',{staticStyle:{"width":"100%","height":"600px","z-index":"1"},attrs:{"zoom":_vm.zoom,"center":_vm.center}},[_c('l-tile-layer',{attrs:{"url":_vm.currentMapLayer.url}}),_c('l-tile-layer',{attrs:{"url":_vm.currentMapLayer.texturl,"attribution":_vm.currentMapLayer.attribution}}),_vm._l((_vm.polygonArr),function(polygon){return _c('l-polygon',{key:polygon.latlngs,attrs:{"name":polygon.name,"visible":polygon.visible,"lat-lngs":polygon.latlngs,"color":polygon.color}})}),_vm._l((_vm.polylines),function(polyline){return _c('l-polyline',{key:polyline.id,attrs:{"lat-lngs":polyline.path,"opacity":polyline.strokeOpacity,"dash-array":polyline.dashArray,"color":polyline.strokeColor,"weight":polyline.strokeWeight}})}),_vm._l((_vm.pointsList),function(item){return _c('l-marker',{key:item.id,attrs:{"icon":_vm.icon(),"lat-lng":[item.lng_lat_gaode.lat, item.lng_lat_gaode.lng],"offset":[8, 0]}},[_c('l-tooltip',{attrs:{"options":{
            permanent: true,
            interactive: true,
            offset: [4, -16],
            className: 'color'
          }}},[_c('div',{staticClass:"tooltipInnerBox"},[_c('img',{attrs:{"cLass":"icon","src":item.imgurl}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.name)+" ")])])])],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }