<template>

  <div :class="{
    'maincon':true
  }">

 
   <div class="bmap-page-container">
        <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
     <l-map style="width: 100%; height:600px;z-index:1"  :zoom="zoom" :center="center">
           <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>


            <l-polygon
            v-for="polygon in polygonArr"
            :key="polygon.latlngs"
            :name="polygon.name"
            :visible="polygon.visible"
            :lat-lngs="polygon.latlngs"
            :color="polygon.color"
          />
           <l-polyline v-for="polyline in polylines" :key="polyline.id" :lat-lngs="polyline.path" 
        :opacity="polyline.strokeOpacity"
         :dash-array="polyline.dashArray"
        :color="polyline.strokeColor" :weight="polyline.strokeWeight"></l-polyline>


        
        <l-marker
          v-for="item in pointsList"
          :key="item.id"
          :icon="icon()"
          :lat-lng="[item.lng_lat_gaode.lat, item.lng_lat_gaode.lng]"
          :offset="[8, 0]"
        >
         
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color'
            }"
           
          >
            <div class="tooltipInnerBox">
              <img cLass="icon" :src="item.imgurl" />
              <div class="title">
                {{ item.name}}
              </div>
            </div>
          </l-tooltip>
          
        </l-marker>



        </l-map> 
    </div> 

       




  </div>
</template>

<script>

import { LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon } from 'vue2-leaflet';
  import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";


import { latLng, icon } from "leaflet";
import { pointData, polygonData, lineData } from '@/api/cityresearch'

export default {
  components: {
     LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
  },

  props: {

    pointcate: String,
    linecate: String,
    cname: String,
    city_plate_id: {
      type: Number,
    },
    isBlock: {
      props: Boolean,
      default: false
    },
    polygondata: {

      type: Array,
      default: function () {
        return []
      }
    }
  },
  mounted() {
    this.getPointData()
    this.getLineData()
  },
  watch: {
     getCityLayer(){
            return this.cname;
        },
   
      'currentArea':{
          handler(newVal,oldVal){
              this.getCityBoundaryData()
          },
          deep:true,
          immediate:true
      },




    pointcate(v) {
      this.getPointData()
    },
    linecate() {
      this.getLineData()
    },
    shi(v) {
      this.getLineData()
      // if (v !== 0) {
      //   this.getLineData()
      // }

    },
    block(v) {
      // this.getLineData()
      if (v !== 0) {
        this.getPolygonData()
      }

    },
    city_plate_id(v) {

      // alert(v)
      // this.getPointData()



    },
     cname(v) {
      var that = this
    
      that.getPointData()
      that.getPolygonData()

    },




    polygondata: {
      handler(v) {
        // console.log('==data==', v)

        v.map(item => {
          this.handlerData(item.set)
        })
      },
      deep: true
    }


  },

  computed: {
    shi: {
      get() {
        return this.$store.state.shi
      },
      set(v) {
        this.$store.dispatch('changeShi', v);
      }
    },
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.dispatch('changeBlock', v);
      }
    },



     getCityLayer(){
            return this.cname;
        },
      currentArea: {
          get() {
              return this.$store.state.currentArea
          }
      }


   
  },
  data() {
    return {
             //矢量地图对象
       vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},

        polygonArr:[],
      zoom: 12,
       crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
      center: L.latLng(),
      
      // center: [0, 0],
      url:leafletmapurl,
      attribution: '',
      marker: L.latLng(18.212516, 109.482965),
      text: 'this is a marker',
      showParagraph: false,

      drwaPolygonArr: [],
      setMapMode: '地图',
      blockoverlay: {},//板块多边形对象
      visible: true,
      polylines: [],
      polygons: [

      ],
      area: '',
     
      pointsList:[],
      count: 1,
    };
  },
   created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },


  methods: {
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },

    icon(num) {
        if(num == null || num == ''){

          return icon({
            iconUrl: require("@/assets/opcity.png"),
            iconSize: [32, 37],
            iconAnchor: [16, 37]
          });
        }else{
          return icon({
            iconUrl: require("@/assets/type" + num + ".png"),
            iconSize: [32, 37],
            iconAnchor: [16, 37],
          });
        }
      
      },




    bd_convert_gd(coordinate) {
          var bd_lng = coordinate[0];
          var bd_lat = coordinate[1];
          var pi = 3.14159265358979324 * 3000.0 / 180.0;
          var x = bd_lng - 0.0065;
          var y = bd_lat - 0.006;
          var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * pi);
          var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * pi);
          var gd_lng = z * Math.cos(theta);
          var gd_lat = z * Math.sin(theta);
          return [gd_lng,gd_lat];
      },
    getCityBoundaryData(){
        const params = {
            city_plate_id: this.currentArea,
            type: 10,
            is_gaode:isgaode
        }

        if(this.currentArea){
            polygonData(params).then(res => {
                const polygondata = res.data
                polygondata.map(item => {
                    this.handlerDataarea(item.set)
                })

            })
        }
        
    },
     // 处理数据
    handlerDataarea(data) {
        this.polygonArr = []
        const res = JSON.parse(data)
        this.center = L.latLng(res[0].centerpoint.split(',')[1], res[0].centerpoint.split(',')[0])
        res.map(item=>{
            

            const polygon = item.multipoint.split(';')
            const polygonArrs = []
            
            polygon.map(item => {
                const arr = item.split(',')
             
                let newarr
                    if(isgaode){
                      newarr = this.bd_convert_gd([arr[0], arr[1]])
                    }else{
                      newarr = arr
                    }

                polygonArrs.push([newarr[1], newarr[0]])
            })
            const polygonObj = {
                 color: "#000000",
                 fillColor: "#000000",
                 fillOpacity: 0.6,
          
                latlngs: polygonArrs
            }
            this.polygonArr.push(polygonObj)
            console.log('this.polygonArr',this.polygonArr)
        })
        

      

    },






    handleImg(data) {
      const res = data.map(item => {
        item.imgurl = require('@/assets/icon/jiaotong' + item.type + '.png')
        return item
      })
      return res
    },



    //获取点数据
    getPointData() {
      const params = {
        city_plate_id: this.shi,
        type: this.pointcate,
        is_gaode:isgaode
      }

      if (this.city_plate_id) {
        pointData(params).then(res => {
          // console.log('===', res)
          this.pointsList = this.handleImg(res.data)
        })
      }
    },

    getLineData() {

      const params = {
        // city_plate_id: this.city_plate_id,
        city_plate_id: this.shi,
        type: this.linecate,
        is_gaode:isgaode
      }


      if (this.shi) {
        lineData(params).then(res => {



          const lineArr = res.data
          const polylineArr = lineArr.map(line => {
            const LinePointArr = JSON.parse(line.set_gaode)
            // console.log('LinePointArr', LinePointArr)
            let path = []
            LinePointArr.map(item => {
              path.push([ item.lat,item.lng])
            })

            //判断虚线和实线
            let  dasharray = ''
            if(line.strokestyle === "dashed"){
              dasharray = '5,10'
            }else{
              dasharray = ''
            }
            return {
              path: path,
              visible: true,
              strokeColor: line.color,	//String	//线条颜色，使用16进制颜色代码赋值。默认值为#006600
              strokeOpacity: Number(line.transparency),	//Number	线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
              strokeWeight: Number(line.width),	//Number	线条宽度，单位：像素
              dashArray:dasharray
            }

          })
          this.polylines = polylineArr


          // const lineArr = res.data
          
          // this.polylines  = []
          // const polylineArr = lineArr.map(line => {
          //   const LinePointArr = JSON.parse(line.set)
          //   // console.log('LinePointArr', LinePointArr)
          //   let path = []
          //   LinePointArr.map(item => {
          //     path.push([item.lng, item.lat])
          //   })
          //   return {
          //     id:line.id,
          //     path: path,
          //     visible: true,
          //     strokeColor: line.color,	//String	//线条颜色，使用16进制颜色代码赋值。默认值为#006600
          //     strokeOpacity: Number(line.transparency),	//Number	线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
          //     strokeWeight: Number(line.width),	//Number	线条宽度，单位：像素
          //     strokeStyle: line.strokestyle	//Number	线条宽度，单位：像素
          //   }
          //   // alert(line.strokestyle)

          // })

          // // console.log('=====',polylineArr)
          // this.polylines = polylineArr


        })
      }
    },

    // 处理数据
    handlerData(data) {
      const dataArr = JSON.parse(data) // 获取地图数据数组
      dataArr.map(obj => {
        this.drawPloygonByBD(obj)
      })
    },




    // 获取手动画 数据库api数据 画多变形
    drawPloygonByBD(obj) {
      // this.map.clearOverlays()// 添加覆盖物
      var that = this
      this.drwaPolygonArr.map(ply => {
        // console.log(' this.ply', ply)
            that.map.removeOverlay(ply)
      })
      


      var ply = new BMapGL.Polygon(obj.multipoint, {
        strokeWeight: 2,
        // strokeColor: obj.strokecolor
        strokeStyle: 'dashed',
        fillColor: '#dedede',
        strokeColor: '#000000'


      }) // 建立多边形覆盖物
      this.blockoverlay = ply
      this.drwaPolygonArr.push(ply)
      this.map.addOverlay(ply) // 添加覆盖物
      // this.map.setViewport(ply.getPath()) // 调整视野
    },






    toChangeLocation() {

    },

    changeCenter() {
      let o = this.bmapManager.getMap()
      // console.log(o)
    },

    getPolygonData() {
      // alert(this.currentArea)
      let currentAreaid
      if (this.isBlock) {
        currentAreaid = this.block
      } else {
        currentAreaid = this.currentArea
      }
      // alert('block='+currentAreaid)

      const params = {
        city_plate_id: currentAreaid,
        type: 10,
        is_gaode:isgaode
      }

      polygonData(params).then(res => {
        const polygondata = res.data
        polygondata.map(item => {
          this.handlerData(item.set)
        })

      })
    },


    setAreaLine(cname) {
      if (cname) {

        var bdary = new BMapGL.Boundary();
        var that = this;
        bdary.get(cname, function (rs) {
          //这里是用户自己的函数。 

          that.drwaPolygonArr.map(ply => {
            that.map.removeOverlay(ply)
          })
          that.polygons = []
          // alert()
          if (that.isBlock || rs.boundaries.length === 0) { //自定义区域画多变形
            // if (isBlock)
            that.getPolygonData()
          } else {
            const newbounderies = rs.boundaries.map(item => {
              let single = item.split(';');
              let newItem = single.map(v => {
                return v.split(',')
              })
              const object = {
                path: newItem,

              }
              

              return object;
            })

            that.polygons = newbounderies;
            // that.map.setViewport(newbounderies) // 调整视野
          }

        });
      }

    }




  },


};
</script> 
<style lang="scss" scoped>
.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}




/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution, .leaflet-control {
    display: none !important;
    }



.maincon {
  position: relative;
  width: 100%;
  height: calc(100vh - 220px);
}
// .isBlock{
//   height: calc(100vh - 20px);
// }


.tooltipInnerBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon{

    height: 30px;
    width: 30px;
  }
  .title{
    font-size: 16px;
    margin-left:5px;
  }
}

.contrl {
  position: absolute;
  ;
  right: 0;
  top: 0;
  width: 200px;
  background: #fff;
  height: calc(100vh - 220px);
  z-index: 9999
}

.bmap-demo {
  height: calc(100vh - 140px);
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;


  align-items: flex-start;
  border-radius: 5px;

  .labelcontent {
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    margin-left:15px;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;

  }
}
</style>